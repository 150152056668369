const SEPOLIA_LP_CONTRACT_ADDRESS = "0x78d10FDA5670f8abe61682665e57213E63809A68";
const ETHEREUM_LP_CONTRACT_ADDRESS = "";
const BSC_LP_CONTRACT_ADDRESS = "";
const AVAX_LP_CONTRACT_ADDRESS = ""; 
const ARBITRUM_LP_CONTRACT_ADDRESS = ""; 
const BASE_LP_CONTRACT_ADDRESS = "";
const POLYGON_LP_CONTRACT_ADDRESS = "0x075572f8C138659826FAaf2D7b61e540dCD4C114";
const POLYGON_LIN_CONTRACT_ADDRESS = "0x8d6827bf7af483b71c26be6c232e89163036235e";
const POLYGON_LOG_CONTRACT_ADDRESS = "0x7e943b41983711b5ed6d5fb95b7822b4f772d1fd"; 
const POLYGON_EXP_CONTRACT_ADDRESS = "0xcecbea064e6066e8c01c3d246998dfba16763cc4"; 
export {SEPOLIA_LP_CONTRACT_ADDRESS, BSC_LP_CONTRACT_ADDRESS, ETHEREUM_LP_CONTRACT_ADDRESS, AVAX_LP_CONTRACT_ADDRESS, ARBITRUM_LP_CONTRACT_ADDRESS, BASE_LP_CONTRACT_ADDRESS, POLYGON_LP_CONTRACT_ADDRESS, POLYGON_LIN_CONTRACT_ADDRESS, POLYGON_LOG_CONTRACT_ADDRESS, POLYGON_EXP_CONTRACT_ADDRESS};