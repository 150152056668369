import React, { useEffect, useRef, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { AppBar, Box, Button, Toolbar, Typography, styled, IconButton, useTheme } from "@mui/material";
import axios from "axios";
import { socket } from "../../utils/socket";
import { useNavigate } from "react-router-dom";

const CyberAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(45deg, #1C2132, #2a3a5a)',
  borderBottom: '2px solid var(--primary-color)',
  boxShadow: '0 0 10px var(--primary-color)',
}));

const CyberButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, var(--primary-color), var(--secondary-color))',
  border: 'none',
  borderRadius: '5px',
  color: 'var(--background-color)',
  fontFamily: 'Orbitron, sans-serif',
  fontWeight: 'bold',
  padding: '6px 12px',
  textTransform: 'uppercase',
  transition: 'all 0.3s ease',
  fontSize: '0.7rem',
  whiteSpace: 'nowrap',
  minWidth: 'auto',
  '&:hover': {
    background: 'linear-gradient(45deg, var(--secondary-color), var(--primary-color))',
    boxShadow: '0 0 10px var(--primary-color)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',
    padding: '4px 8px',
  },
}));

const ScrollingBox = styled(Box)({
  display: 'flex',
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  flex: 1,
  marginRight: '16px',
});

const TokenLink = styled('a')({
  marginRight: '1rem',
  background: '#ffffff29',
  padding: '0.25rem',
  borderRadius: '2rem',
  textDecoration: 'none',
  color: 'white',
  display: 'inline-flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  flexShrink: 0,
});

const NeonText = styled(Typography)<{ $isLit: boolean }>(({ theme, $isLit }) => ({
  color: $isLit ? 'var(--accent-color)' : 'var(--primary-color)',
  textShadow: $isLit
    ? '0 0 5px var(--secondary-color), 0 0 10px var(--accent-color)'
    : '0 0 5px var(--primary-color), 0 0 10px var(--primary-color)',
  fontFamily: 'Orbitron, sans-serif',
  marginRight: '0.5rem',
  fontSize: '0.8rem',
  transition: 'color 0.3s ease, text-shadow 0.3s ease',
  '@media (max-width: 600px)': {
    fontSize: '0.7rem',
  },
}));

const TokenImage = styled('img')({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
});

const IMAGE_BASE_URL = "https://lime-capitalist-mole-212.mypinata.cloud/ipfs/";

interface Transaction {
  id: number;
  tokenName: string;
  tokenSymbol: string;
  tokenAddress: string;
  tokenImage: string;
  type: string;
  ethAmount: string;
  // Add other fields as necessary
}

interface HeaderProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

function Header({ drawerWidth, handleDrawerToggle }: HeaderProps) {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const [latestBuyTokens, setLatestBuyTokens] = useState<Transaction[]>([]);
  const [litTokens, setLitTokens] = useState<Set<number>>(new Set());
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const ownerAddress = "0xf3663B50babCe3B54b26743498b20079911F406f"; //owner wallet
  const theme = useTheme();

  const getLatestTrade = async () => {
    setLoading(true);
    setError(null);
    try {
      console.log("Fetching data from:", `${process.env.REACT_APP_API_URL}`);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}`);
      console.log("Full API Response:", response.data);

      if (!Array.isArray(response.data)) {
        console.error("API did not return an array:", response.data);
        setError("Invalid data format received from API");
        setLatestBuyTokens([]);
        return;
      }

      // Filter only buy transactions
      const buyTransactions = response.data.filter((trade: Transaction) => {
        console.log("Checking trade:", trade);
        return trade.type.toLowerCase() === 'buy';
      });

      console.log("Filtered Buy Transactions:", buyTransactions);
      
      if (buyTransactions.length === 0) {
        console.log("No buy transactions found in the data");
      }

      setLatestBuyTokens(buyTransactions);
    } catch (error) {
      console.error("Error fetching latest trades:", error);
      setError("Failed to fetch trade data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLatestTrade();
    socket.on("message", getLatestTrade);
    return () => {
      socket.off("message", getLatestTrade);
    };
  }, []);

  useEffect(() => {
    console.log("latestBuyTokens updated:", latestBuyTokens);
  }, [latestBuyTokens]);

  useEffect(() => {
    if (scrollRef.current && latestBuyTokens.length > 0) {
      const scrollWidth = scrollRef.current.scrollWidth;
      const clientWidth = scrollRef.current.clientWidth;
      let scrollPos = 0;

      const scroll = () => {
        if (scrollRef.current) {
          scrollPos += 1;
          if (scrollPos >= scrollWidth / 2) {
            scrollPos = 0;
          }
          scrollRef.current.scrollLeft = scrollPos;
        }
      };

      const scrollInterval = setInterval(scroll, 30);

      return () => clearInterval(scrollInterval);
    }
  }, [latestBuyTokens]);

  useEffect(() => {
    const lightUpInterval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * latestBuyTokens.length);
      setLitTokens(prev => {
        const newSet = new Set(prev);
        newSet.add(randomIndex);
        return newSet;
      });

      setTimeout(() => {
        setLitTokens(prev => {
          const newSet = new Set(prev);
          newSet.delete(randomIndex);
          return newSet;
        });
      }, 1000);
    }, 2000);

    return () => clearInterval(lightUpInterval);
  }, [latestBuyTokens]);

  const renderScrollingContent = () => {
    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;
    if (latestBuyTokens.length === 0) return <Typography>No buy transactions available</Typography>;

    return (
      <>
        {latestBuyTokens.map((item: Transaction, index: number) => (
          <TokenLink key={index} href={`/Polygon/${item.tokenAddress}`}>
            <NeonText $isLit={litTokens.has(index)}>{`Buy ${item.ethAmount} of ${item.tokenSymbol}`}</NeonText>
            <TokenImage src={`${IMAGE_BASE_URL}${item.tokenImage}`} alt={item.tokenSymbol} />
          </TokenLink>
        ))}
      </>
    );
  };

  return (
    <CyberAppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", px: { xs: 1, sm: 2 }, height: '64px' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 1, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', overflow: 'hidden' }}>
          <ScrollingBox ref={scrollRef}>
            {renderScrollingContent()}
            {!loading && !error && latestBuyTokens.length > 0 && renderScrollingContent()}
          </ScrollingBox>
          <Box sx={{ display: 'flex', gap: 1, flexShrink: 0 }}>
            <CyberButton onClick={() => open()} sx={{ mr: 1 }}>
              {isConnected ? `${address?.slice(0, 3)}...${address?.slice(-2)}` : "Connect"}
            </CyberButton>
            {(address?.toLowerCase() === ownerAddress?.toLowerCase()) && (
              <CyberButton 
                onClick={() => navigate('/admin')} 
                sx={{ 
                  backgroundImage: "linear-gradient(90deg, #6928d5 14.14%, #9E1F63 100%)"
                }}
              >
                Admin
              </CyberButton>
            )}
          </Box>
        </Box>
      </Toolbar>
    </CyberAppBar>
  );
}

export default Header;